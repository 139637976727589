<template>
    <div class="p-10 overflow-y-auto absolute left-0 top-0 w-full h-full">
        <h2 class="text-center mb-14 font-bold text-28">Sammelpass füllen</h2>
        
        <svg class="mb-14 mx-auto w-full max-w-36" viewBox="0 0 165 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2268 183.265V16.9165C13.2268 8.13898 20.3467 1.01611 29.1204 1.01611H135.333C144.107 1.01611 151.227 8.13898 151.227 16.9165V183.285" fill="white"/>
            <path d="M13.2268 183.265V16.9165C13.2268 8.13898 20.3467 1.01611 29.1204 1.01611H135.333C144.107 1.01611 151.227 8.13898 151.227 16.9165V183.285" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M66.5552 21.053H100.884" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M37.6119 81.2441C43.9056 81.2441 49.0076 76.1399 49.0076 69.8435C49.0076 63.5471 43.9056 58.4429 37.6119 58.4429C31.3181 58.4429 26.2161 63.5471 26.2161 69.8435C26.2161 76.1399 31.3181 81.2441 37.6119 81.2441Z" fill="black" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M67.3418 81.2441C73.6356 81.2441 78.7376 76.1399 78.7376 69.8435C78.7376 63.5471 73.6356 58.4429 67.3418 58.4429C61.0481 58.4429 55.946 63.5471 55.946 69.8435C55.946 76.1399 61.0481 81.2441 67.3418 81.2441Z" fill="black" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M97.0918 81.2441C103.386 81.2441 108.488 76.1399 108.488 69.8435C108.488 63.5471 103.386 58.4429 97.0918 58.4429C90.7981 58.4429 85.696 63.5471 85.696 69.8435C85.696 76.1399 90.7981 81.2441 97.0918 81.2441Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M126.842 81.2441C133.136 81.2441 138.238 76.1399 138.238 69.8435C138.238 63.5471 133.136 58.4429 126.842 58.4429C120.548 58.4429 115.446 63.5471 115.446 69.8435C115.446 76.1399 120.548 81.2441 126.842 81.2441Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M37.6119 114.881C43.9056 114.881 49.0076 109.777 49.0076 103.48C49.0076 97.1841 43.9056 92.0798 37.6119 92.0798C31.3181 92.0798 26.2161 97.1841 26.2161 103.48C26.2161 109.777 31.3181 114.881 37.6119 114.881Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M67.3418 114.881C73.6356 114.881 78.7376 109.777 78.7376 103.48C78.7376 97.1841 73.6356 92.0798 67.3418 92.0798C61.0481 92.0798 55.946 97.1841 55.946 103.48C55.946 109.777 61.0481 114.881 67.3418 114.881Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M97.0918 114.881C103.386 114.881 108.488 109.777 108.488 103.48C108.488 97.1841 103.386 92.0798 97.0918 92.0798C90.7981 92.0798 85.696 97.1841 85.696 103.48C85.696 109.777 90.7981 114.881 97.0918 114.881Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M126.842 114.881C133.136 114.881 138.238 109.777 138.238 103.48C138.238 97.1841 133.136 92.0798 126.842 92.0798C120.548 92.0798 115.446 97.1841 115.446 103.48C115.446 109.777 120.548 114.881 126.842 114.881Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M37.6119 145.713C43.9056 145.713 49.0076 140.609 49.0076 134.313C49.0076 128.016 43.9056 122.912 37.6119 122.912C31.3181 122.912 26.2161 128.016 26.2161 134.313C26.2161 140.609 31.3181 145.713 37.6119 145.713Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M67.3418 145.713C73.6356 145.713 78.7376 140.609 78.7376 134.313C78.7376 128.016 73.6356 122.912 67.3418 122.912C61.0481 122.912 55.946 128.016 55.946 134.313C55.946 140.609 61.0481 145.713 67.3418 145.713Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M97.0918 145.713C103.386 145.713 108.488 140.609 108.488 134.313C108.488 128.016 103.386 122.912 97.0918 122.912C90.7981 122.912 85.696 128.016 85.696 134.313C85.696 140.609 90.7981 145.713 97.0918 145.713Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M126.842 145.713C133.136 145.713 138.238 140.609 138.238 134.313C138.238 128.016 133.136 122.912 126.842 122.912C120.548 122.912 115.446 128.016 115.446 134.313C115.446 140.609 120.548 145.713 126.842 145.713Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M37.6119 176.566C43.9056 176.566 49.0076 171.461 49.0076 165.165C49.0076 158.869 43.9056 153.764 37.6119 153.764C31.3181 153.764 26.2161 158.869 26.2161 165.165C26.2161 171.461 31.3181 176.566 37.6119 176.566Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M67.3418 176.566C73.6356 176.566 78.7376 171.461 78.7376 165.165C78.7376 158.869 73.6356 153.764 67.3418 153.764C61.0481 153.764 55.946 158.869 55.946 165.165C55.946 171.461 61.0481 176.566 67.3418 176.566Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M97.0918 176.566C103.386 176.566 108.488 171.461 108.488 165.165C108.488 158.869 103.386 153.764 97.0918 153.764C90.7981 153.764 85.696 158.869 85.696 165.165C85.696 171.461 90.7981 176.566 97.0918 176.566Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M126.842 176.566C133.136 176.566 138.238 171.461 138.238 165.165C138.238 158.869 133.136 153.764 126.842 153.764C120.548 153.764 115.446 158.869 115.446 165.165C115.446 171.461 120.548 176.566 126.842 176.566Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
        </svg>

        <p class="text-center">Mit jedem Scan, füllt sich dein Sammelpass und du erhöhst deine Chancen für die Verlosung. Mit bereits 5 Scans bekommst du dein exklusives Gamers Heaven Goodie.</p>
    </div>
</template>