<script setup>
import { ref } from 'vue';
import FloatingLabelInput from '../../../Common/FloatingLabelInput.vue';
import Loader from '../../../Common/Loader.vue';
import Icon from '../../../Common/Icon.vue';
import { useAuthStore } from '../../../../stores/auth';

const emit = defineEmits(["continue"]);

const isLoading = ref(false);

const gamerTag = ref(null);
const firstName = ref(null);
const lastName = ref(null);
const birthDate = ref(null);
const street = ref(null);
const zip = ref(null);
const city = ref(null);
const country = ref(null);
const email = ref(null);
const password = ref(null);
const terms = ref(false);
const newsletter = ref(false);

const authStore = useAuthStore();

const error = ref('');

async function onSubmit(){
    isLoading.value = true;
    const formData = getFormData();

    const response = await authStore.register(formData);

    isLoading.value = false;

    if(response.success){
        emit('continue', {
            data: formData
        });
        
        return;
    }
    
    error.value = response.error;
}

function getFormData(){
    return {
        gamerTag: gamerTag.value,
        firstName: firstName.value,
        lastName: lastName.value,
        birthDate: birthDate.value,
        street: street.value,
        zip: zip.value,
        city: city.value,
        country: country.value,
        email: email.value,
        password: password.value,
        newsletter: newsletter.value,
    }
}
</script>

<template>
    <div>
        <div class="flex items-center gap-x-2.5">
            <div class="shrink-0 size-4 -translate-y-0.5 bg-black rounded-full text-white flex items-center justify-center">
                <Icon name="info-i" class="shrink-0 size-3" />
            </div>
            <span class="text-12">Alle Felder sind Pflichtfelder und für die Teilnahme sowie die Auslosung des Gewinnspiels erforderlich.</span>
        </div>

        <form @submit.prevent="onSubmit" class="mt-8">
            <div class="space-y-5">
                <FloatingLabelInput v-model="gamerTag" label="Gamertag (Nickname)" name="gamerTag" :required="true" />
                <FloatingLabelInput v-model="firstName" label="Vorname" name="firstName" :required="true" />
                <FloatingLabelInput v-model="lastName" label="Nachname" name="lastName" :required="true" />
                <FloatingLabelInput v-model="birthDate" label="Geburtsdatum" name="birthDate" type="date" :required="true" />
                <FloatingLabelInput v-model="street" label="Straße / Hausnummer" name="street" :required="true" />
                
                <div class="grid gap-x-5 grid-cols-2">
                    <FloatingLabelInput v-model="zip" label="PLZ" name="zip" :required="true" />
                    <FloatingLabelInput v-model="city" label="Ort" name="city" :required="true" />
                </div>

                <FloatingLabelInput v-model="country" label="Land" name="country" :required="true" />
                <FloatingLabelInput v-model="email" label="Email" name="email" type="email" :required="true" />
                <FloatingLabelInput v-model="password" label="Passwort" name="password" type="password" :required="true" />
            </div>

            <div class="flex gap-x-3 items-start mt-9">
              <input type="checkbox" class="accent-blue-400 size-5 shrink-0" name="newsletter" id="newsletter" v-model="newsletter">
              <label class="text-12" for="newsletter">
                Ich möchte mich für den <b>Newsletter</b> anmelden und über aktuelle Angebote und Gewinnspiele informiert werden.
              </label>
            </div>

            <div class="flex gap-x-3 items-start mt-9">
                <input required type="checkbox" class="accent-blue-400 size-5 shrink-0" name="terms" id="terms" v-model="terms">
                <label class="text-12" for="terms">
                    Ich habe die
                    <a class="text-blue-400 underline underline-offset-2" target="_blank" href="https://gamersheaven.tirol/datenschutz">Datenschutzerklärung</a>
                    und die
                    <a class="text-blue-400 underline underline-offset-2" target="_blank" href="https://gamersheaven.tirol/agbs">AGBs</a>
                    gelesen und stimme diesen zu.
                </label>
            </div>

            <p v-if="error" v-text="error" class="text-violet mt-9"></p>

            <button type="submit" class="button-black w-full mt-10">
                <Loader class="mx-auto size-6" v-if="isLoading" />
                <span v-else>
                    Jetzt registrieren
                </span>
            </button>

        </form>
    </div>
</template>
