<script setup>
import { ref, computed } from 'vue';
import Icon from './Icon.vue';

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: "text",
  },
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: true,
    required: false,
    default: false,
  },
});

const value = ref(props.modelValue);
const isFocused = ref(false);
const isFloating = computed(() => {
    // Float if input is focused or filled
    return isFocused.value || value.value;
})

const isPassword = props.type === 'password';
const showPassword = ref(false);

const type = computed(() => {
    if(isPassword && showPassword.value){
        return 'text';
    }

    return props.type;
})
</script>

<template>
  <div class="relative w-full text-16" :class="props.disabled ? 'opacity-50 pointer-events-none' : ''">
    <label
        class="absolute left-1 transition-all duration-300 ease-in-out origin-left -translate-y-1/2 bg-white px-3"
        :for="props.name"
        v-text="props.label"
        :class="isFloating ? 'top-0 text-opacity-50 scale-75' : 'top-1/2'"
    ></label>
    <input
        class="bg-white border rounded-sm leading-175 outline-none w-full border-gray-200 p-4"
        :class="isPassword ? 'pr-20' : ''"
        @blur="isFocused = false"
        @focus="isFocused = true"
        @input="emit('update:modelValue', value)"
        v-model="value"
        :required="props.required"
        :type="type"
        :name="props.name"
        :id="props.name" 
        :disabled="props.disabled"
    />
    <button
        @touchstart="showPassword = true"
        @touchend="showPassword = false"
        type="button"
        class="absolute right-0 top-0 h-full aspect-square flex items-center justify-center"
        v-if="isPassword"
    >
        <Icon name="eye" class="shrink-0 size-6"/>
    </button>
  </div>
</template>
