<script setup>
import { ref } from 'vue';
import Icon from './Icon.vue';

const emit = defineEmits(["swipe"]);
const props = defineProps({
  text: {
    type: String,
  },
});

// https://codepen.io/gpressutto5/pen/NjJobG

const initialMouse = ref(0);
const slideMovementTotal = ref(0);
const mouseIsDown = ref(false);

// Template refs
const background = ref(null);
const button = ref(null);

function onTouchStart(event){
    mouseIsDown.value = true;
    
    const endOffset = 7;
	slideMovementTotal.value = background.value.getBoundingClientRect().width - button.value.getBoundingClientRect().width - endOffset;
	initialMouse.value = event.clientX || event.touches[0].pageX;

    button.value.style.setProperty('transition', 'none');
}

function onTouchEnd(event){
	if (!mouseIsDown.value){
		return;
    }

	mouseIsDown.value = false;
    button.value.style.removeProperty('transition');

	const currentMouse = event.clientX || event.changedTouches[0].pageX;
	const relativeMouse = currentMouse - initialMouse.value;

	if (relativeMouse < slideMovementTotal.value) {
        button.value.style.setProperty('transform', `translateX(0px)`);
		return;
	}

	emit('swipe')
}

function onTouchMove(event){
    if (!mouseIsDown.value){
		return;
    }

	const currentMouse = event.clientX || event.touches[0].pageX;
	const relativeMouse = currentMouse - initialMouse.value;

    if (relativeMouse <= 0) {
        button.value.style.setProperty('transform', `translateX(0px)`);
		return;
	}

    if (relativeMouse >= slideMovementTotal.value) {
        button.value.style.setProperty('transform', `translateX(${slideMovementTotal.value}px)`);
        return;
	}

    button.value.style.setProperty('transform', `translateX(${relativeMouse}px)`);
}

document.body.addEventListener('touchend', (e) => {onTouchEnd(e)})
document.body.addEventListener('mouseup', (e) => {onTouchEnd(e)})

document.body.addEventListener('mousemove', (e) => {onTouchMove(e)})
document.body.addEventListener('touchmove', (e) => {onTouchMove(e)})

</script>

<template>
  <div ref="background" class="bg-gray-200 relative overflow-hidden isolate border-3 border-gray-200 rounded-full">
    <div
        ref="button"
        class="bg-black z-10 relative size-14 rounded-full flex items-center justify-center text-white transition-transform duration-300 ease-in-out after:absolute after:right-1/2 after:top-0 after:w-screen after:h-full after:bg-black after:-z-10"
        @touchstart="onTouchStart"
        @mousedown="onTouchStart"
        @touchend="onTouchEnd"
        @mouseup="onTouchEnd"
    >
        <Icon name="caret-right" class="size-4 shrink-0" />
    </div>

    <span v-text="props.text" class="absolute z-20 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white pointer-events-none"></span>
  </div>
</template>
