import { createRouter, createWebHistory } from "vue-router";
import Start from "../views/Start.vue";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import Scan from "../views/Scan.vue";
import AuthLoading from "../views/AuthLoading.vue";
import { useAuthStore } from "../stores/auth";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "start",
      component: Start,
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        protected: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        protected: true,
      },
    },
    {
      path: "/scan",
      name: "scan",
      component: Scan,
      meta: {
        protected: true,
      },
    },
    {
      path: "/loading-auth",
      name: "loading-auth",
      component: AuthLoading,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from) => {
  const authStore = useAuthStore();
  
  // Go to loading screen while authenticating
  if(authStore.isLoading && to.name !== 'loading-auth'){
    // Redirect to original url afterwards, unless the original url was the start-screen, redirect to home in this case
    const referer = to.name === 'start' ? 'home' : to.fullPath;
    return `/loading-auth?referer=${encodeURI(referer)}`;
  }
  
  // Return to / on protected routes if user is not logged in
  if (to.meta?.protected && !authStore.isLoggedIn) {
    console.log('RESTRICTED');
    return "/";
  }
});

export default router;
