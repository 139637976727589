<template>
    <div class="p-10 overflow-y-auto absolute left-0 top-0 w-full h-full">
        <h2 class="text-center mb-14 font-bold text-28">QR-Codes scannen</h2>
        
        <svg class="mb-14 mx-auto w-full max-w-36" viewBox="0 0 165 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M112.977 119.457V169.832C112.977 176.015 107.976 181.033 101.812 181.033H27.1647C21.0015 181.033 16 176.015 16 169.832V12.209C16 6.02575 21.0015 1.00806 27.1647 1.00806H101.812C107.976 1.00806 112.977 6.02575 112.977 12.209V27.3213" fill="white"/>
            <path d="M112.977 119.457V169.832C112.977 176.015 107.976 181.033 101.812 181.033H27.1647C21.0015 181.033 16 176.015 16 169.832V12.209C16 6.02575 21.0015 1.00806 27.1647 1.00806H101.812C107.976 1.00806 112.977 6.02575 112.977 12.209V27.3213" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M124.87 37.1987H141.194C145.526 37.1987 149.031 40.7151 149.031 45.0611V61.4377" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M76.9036 61.4377V45.0611C76.9036 40.7151 80.4085 37.1987 84.7405 37.1987H101.064" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M149.031 85.3606V101.717C149.031 106.064 145.526 109.58 141.194 109.58H124.87" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M76.9036 85.3606V101.717C76.9036 106.064 80.4085 109.58 84.7405 109.58H101.064" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M88.9346 80.7183V97.5493H113.154" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M104.392 49.249H89.8994V63.7885H104.392V49.249Z" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M136.98 83.2468H122.724V97.5492H136.98V83.2468Z" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M112.78 49.249V72.5003H137" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M106.676 73.1521H89.8994" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M112.78 87.6719H96.0037" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M136.98 49.3674H122.724V63.6698H136.98V49.3674Z" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M16 153.416H113.154" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M53.4912 15.1326H77.6124" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <rect x="14" y="180.008" width="101" height="4" rx="2" fill="black"/>
        </svg>

        <p class="text-center">Scanne den gefundenen QR-Code ab und erhalte einen Coin.</p>
    </div>
</template>