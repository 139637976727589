<script setup>
    import { ref } from 'vue';
    import { useAuthStore } from '../../../../stores/auth';
    import FloatingLabelInput from '../../../Common/FloatingLabelInput.vue';
    import Loader from '../../../Common/Loader.vue';
    
    const emit = defineEmits(["reset", "register"]);

    const email = ref('');
    const password = ref('');
    const error = ref(false);
    const isLoading = ref(false);

    const authStore = useAuthStore();

    async function onLogin(){
        isLoading.value = true;
        const success = await authStore.login(email.value, password.value);
        isLoading.value = false;

        error.value = !success;
    }
</script>

<template>
    <div>
        <img src="/bundles/gamingapp/images/coin-blue.png" class="mx-auto mb-14 size-44 object-contain drop-shadow-coin" />

        <h2 class="text-center font-bold text-40 mb-12">Login to Collect</h2>

        <form @submit.prevent="onLogin" class="space-y-5">
            <FloatingLabelInput v-model="email" label="Email" name="email" type="email" :required="true" />
            <FloatingLabelInput v-model="password" label="Passwort" name="password" type="password" :required="true" />

            <p v-if="error" class="text-violet">Ungültige Benutzerdaten.</p>

            <button type="submit" class="button-black w-full">
                <Loader class="mx-auto size-6" v-if="isLoading" />
                <span v-else>
                    Einloggen
                </span>
            </button>
        </form>

        <div class="flex justify-between gap-x-5 text-12 mt-7">
            <button type="button" @click="emit('reset')">Passwort vergessen</button>
            <button type="button" class="text-right" @click="emit('register')">Jetzt registrieren</button>
        </div>
    </div>
</template>
