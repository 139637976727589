<script setup>
    import { RouterView} from "vue-router";
    import { ModalsContainer } from 'vue-final-modal'
    import { useAuthStore } from './stores/auth';
    
    const authStore = useAuthStore();

    authStore.checkToken();
</script>

<template>
    <main style="height: 100svh;">
        <RouterView />
    </main>
    
    <ModalsContainer />
</template>
