<script setup>
  import { onMounted, ref } from 'vue';
  import Icon from "../components/Common/Icon.vue";
  import CameraError from '../components/Scan/CameraError.vue';
  import Scan from '../components/Scan/Scan.vue';
  import ScanFlash from '../components/Scan/ScanFlash.vue';
  import Loading from '../components/Scan/Loading.vue';
  import { useCoinStore } from '../stores/coin';
  import { useRoute } from 'vue-router';

  const cameraError = ref(false);
  
  const isLoading = ref(null);

  const success = ref(null);
  const errorType = ref(null);

  const coinStore = useCoinStore();
  const route = useRoute();

  onMounted(() => {
    if(route.query.hash){
      onScan(route.query.hash);
    }
  })

  async function onScan(hash){
    isLoading.value = true;
    const response = await coinStore.submitCoinHash(hash)

    success.value = response.success;

    if(!response.success){
      errorType.value = response.error;
    }

    isLoading.value = false;
  }

  function onScanError(){
    success.value = false;
    errorType.value = 'generic'
  }

  function onReset(){
    success.value = null;
    errorType.value = null;
  }
</script>

<template>
  <div class="p-5 pt-14 flex flex-col h-full">
    <div class="shrink-0 flex w-full items-center justify-between mb-10">
      <Icon name="gamers-heaven-logo" class="text-white shrink-0 h-12 w-40" />

      <router-link :to="{name: 'home'}" class="flex items-center justify-center size-12 shrink-0 ml-auto bg-white rounded-full text-black">
        <Icon name="x" class="shrink-0 size-3" />
        <span class="sr-only">Zurück</span>
      </router-link>
    </div>

    <div class="grow bg-white rounded-3xl overflow-y-auto flex flex-col p-7 pt-12">
      <CameraError v-if="cameraError" />
      <Loading
        v-else-if="isLoading"
      />
      <ScanFlash
        v-else-if="success !== null"
        :success="success"
        :error-type="errorType"
        @reset="onReset"
      />
      <Scan
        v-else
        @camera-error="cameraError = true"
        @scan="onScan"
        @scan-error="onScanError"
      />
    </div>
  </div>
</template>
