<script setup>
import { computed, ref } from 'vue';
import Icon from '../Common/Icon.vue';

const emit = defineEmits(['reset']);

const props = defineProps({
  success: {
    type: Boolean,
    required: true
  },
  errorType: {
    type: String,
    required: false,
    default: 'generic',
    validator(value, props) {
      return ['generic', 'already-scanned'].includes(value)
    }
  }
});

const message = computed(() => {
    if(props.success){
        return 'Scan erfolgreich'
    }

    if(props.errorType === 'already-scanned'){
        return 'Bereits gescannt'
    }

    // Generic or all other errors
    return 'Falscher QR-Code'
})

const icon = computed(() => {
    if(props.success){
        return 'face-smile'
    }

    if(props.errorType === 'already-scanned'){
        return 'face-sad'
    }

    // Generic or all other errors
    return 'x-pixelated'
})

</script>

<template>
    <h1 class="shrink-0 font-bold mb-10 text-center text-28">QR-Code scannen</h1>
    <div
      :class="props.success ? 'bg-gradient-green' : 'bg-gradient-pink'"
      class="relative grow rounded-md p-10 flex flex-col justify-center items-center text-white"
    >
        <Icon :name="icon" class="size-24"/>
        <p class="text-center text-16 mt-10" v-text="message"></p>
    </div>
    <div class="mt-10 grid grid-cols-2 gap-x-2">
      <button @click="emit('reset')" class="button-black text-16 w-full !px-0">Scan</button>
      <router-link :to="{name: 'home'}" class=" button-black text-16 w-full !px-0">Übersicht</router-link>
    </div>
</template>