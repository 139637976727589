<script setup>
import { VueFinalModal } from "vue-final-modal";
import Icon from '../Common/Icon.vue';
import {computed, onMounted} from 'vue';
import { useAuthStore } from '../../stores/auth';
import {trackEvent} from "../../matomo/tracking";

const emit = defineEmits(["close"]);
const props = defineProps({
  owner: {
    type: Object,
    required: true,
  }
});

const authStore = useAuthStore();

onMounted(() => {
  trackEvent('coin-view', authStore?.user?.email + ', ' + props.owner.title + ', ' + props.owner.genre);
});

const socials = computed(() => {
    const arr = [];

    ['instagram', 'linkedIn', 'x', 'facebook', 'tikTok', 'youTube'].forEach(social => {
        const link = props.owner[`social${capitalizeFirstLetter(social)}`];
        if(link){
            arr.push({
                icon: `social-${social}`,
                link: link,
                social: social,

            })
        }
    })

    return arr;
})

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

</script>

<template>
  <VueFinalModal
        class="size-full"
        overlay-class="!bg-black/90"
        content-class="size-full flex flex-col p-5 pt-10"
        swipe-to-close="down"
        content-transition="vfm-slide-down"
    >
        <div class="flex w-full items-center justify-between mb-10">
            <Icon name="gamers-heaven-logo" class="text-white shrink-0 h-12 w-40" />

            <button @click="emit('close')" type="button" class="flex items-center justify-center size-12 shrink-0 ml-auto bg-white rounded-full text-black">
                <Icon name="x" class="shrink-0 size-3" />
                <span class="sr-only">Zurück</span>
            </button>
        </div>

        <div class="bg-white rounded-3xl grow p-8 overflow-y-auto">
            <h2 class="text-center mb-10 font-bold text-28" v-text="props.owner.title"></h2>
            <div :style="{backgroundColor: props.owner.logoBackground}" class="aspect-video p-10 mb-10">
                <img
                    :src="props.owner.logo"
                    :alt="props.owner.title"
                    class="size-full object-contain"
                >
            </div>
            <div v-html="props.owner.content" class="text-16 text-center mb-14"></div>
            <div class="flex flex-wrap items-center justify-center gap-x-8 gap-y-4 mb-5">
                <a
                    v-for="social in socials"
                    @click="trackEvent('coin-link-click', authStore?.user?.email + ', ' + social.link)"
                    :key="social.social"
                    :href="social.link"
                    target="_blank"
                >
                    <Icon :name="social.icon" class="size-5 shrink-0" />
                </a>
            </div>
            <a
                v-if="props.owner.website"
                @click="trackEvent('coin-link-click', authStore?.user?.email + ', ' + props.owner.website)"
                :href="props.owner.website"
                v-text="props.owner.website"
                target="_blank"
                class="text-16 block w-fit mx-auto mb-8"
            >
            </a>
            <button type="button" class="button-black w-full" @click="emit('close')">Schließen</button>
        </div>
  </VueFinalModal>
</template>
