<script setup>
import {useAuthStore} from "../../stores/auth";
import {trackEvent} from "../../matomo/tracking";
const authStore = useAuthStore();
</script>

<template>
  <div v-if="authStore.user.goodieClaimed"
      class="text-white bg-gradient-blue rounded-md p-6 mt-6"
  >
    <div class="flex justify-start items-center space-x-5">
      <svg class="w-24 h-20 mx-auto" viewBox="0 0 64 64" xml:space="preserve">
        <rect x="12" y="1" fill="none" stroke="white" stroke-width="2" stroke-miterlimit="10" width="40" height="62"/>
        <circle fill="none" stroke="white" stroke-width="2" stroke-miterlimit="10" cx="32" cy="42" r="12"/>
        <circle fill="none" stroke="white" stroke-width="2" stroke-miterlimit="10" cx="32" cy="16" r="6"/>
        <circle fill="none" stroke="white" stroke-width="2" stroke-miterlimit="10" cx="32" cy="42" r="2.5"/>
      </svg>

      <h2 class="text-28 font-bold m-0">Neues Achievement</h2>
    </div>

    <div class="flex items-center gap-x-2.5 mt-4">
      <span class="text-16">Herzlichen Glückwunsch, du hast den Gamers Heaven Soundtrack freigeschalten:</span>
    </div>

    <audio src="/bundles/gamingapp/sounds/soundtrack.mp3"
           controls
           class="w-full mt-4"
           @play="trackEvent('soundtrack', 'play')"
    />
  </div>
</template>