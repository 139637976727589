<script setup>
import { ref, } from 'vue';

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: [String, null],
    required: true,
  },
  digits: {
    type: [Number],
    required: true,
  }
});

const container = ref(null);

function onInput(e, i){
  const nextInput = getInput(i + 1);
  
  if(nextInput && nextInput.value === ''){
    // Focus next element
    nextInput.focus();
  }else{
    // Blur if there is no next element
    getInput(i).blur();
  }

  emit('update:modelValue', getCodeValue())
}

function getInput(i){
  return container.value.querySelector(`[data-index="${i}"]`);
}

function getCodeValue(){
  let code = '';

  for (let i = 0; i < props.digits; i++) {
    const value = getInput(i + 1).value;
    code += value;
  }

  return code;
}
</script>

<template>
  <div ref="container" class="grid gap-x-2" :style="{'grid-template-columns': `repeat(${props.digits}, minmax(0, 1fr))`}">
      <input
        class="outline-none text-center bg-gray-200 rounded-sm text-40 py-7 px-3"
        required
        type="number"
        v-for="i in props.digits"
        :data-index="i"
        min="0"
        max="9"
        @input="onInput($event, i)"
      >
  </div>
</template>
