<script setup>
import { computed, ref } from 'vue';
import { useAuthStore } from '../../stores/auth';
import Icon from '../Common/Icon.vue';
import { useCoinStore } from '../../stores/coin';

const authStore = useAuthStore();
const coinStore = useCoinStore();

const notification = computed(() => {
    const firstName = authStore.user?.firstName;

    const progress = coinStore.progress;
    
    if(progress === 1){
        return `GG, du hast alle Codes gecleart.`;
    }

    if(progress > 0.666){
        return `Keep grinding ${firstName}, du bist auf dem richtigen Weg!`;
    }

    return `Hi ${firstName}, da hast du noch einiges zu entdecken.`;
})

</script>

<template>
    <div class="bg-white rounded-lg p-4 flex items-center gap-x-5">
        <Icon name="face-smile" class="size-7 shrink-0" />
        <p v-text="notification" class="text-16"></p>
    </div>
</template>
