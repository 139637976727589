<script setup>
import Icon from '../Common/Icon.vue';
import CoinOwnerModal from '../CoinOwner/Modal.vue';
import { useModal } from 'vue-final-modal';

const props = defineProps({
  owner: {
    type: Object,
    required: true,
  }
});

const ownerModal = useModal({
    component: CoinOwnerModal,
    attrs: {
      owner: props.owner,
      onClose() {
        ownerModal.close();
      }
    },
});
</script>

<template>
  <button @click="ownerModal.open()" type="button" class="flex text-left w-full items-stretch gap-x-7 text-white">
    <div :style="{backgroundColor: props.owner.logoBackground}" class="shrink-0 w-1/4 aspect-square p-2">
      <img
        :src="props.owner.logo"
        :alt="props.owner.title"
        class="size-full object-contain"
      >
    </div>
    <div class="grow border-b border-white flex gap-x-3 items-center justify-between">
      <div>
        <h3 class="font-bold mb-2 text-16" v-text="props.owner.title"></h3>
        <p class="text-12" v-text="props.owner.teaser"></p>
      </div>
      <Icon name="caret-right" class="size-4 shrink-0" />
    </div>
  </button>
</template>
