<script setup>
import Icon from '../components/Common/Icon.vue';
import ProgressNotification from '../components/Home/ProgressNotification.vue';
import Menu from '../components/Menu/Menu.vue';
import ManualModal from '../components/Manual/Modal.vue';
import GoodieCard from '../components/Home/GoodieCard.vue';
import LotteryCard from '../components/Home/LotteryCard.vue';
import Coin from '../components/Home/Coin.vue';
import CoinOwner from '../components/Home/CoinOwner.vue';
import { useCoinStore } from '../stores/coin';
import { useModal } from 'vue-final-modal';
import AchievementSoundtrack from "../components/Home/AchievementSoundtrack";

const coinStore = useCoinStore();

const manualModal = useModal({
  component: ManualModal,
  attrs: {
    onClose() {
      manualModal.close();
    }
  },
});

// trigger manualMode once if user has not seen it yet
if (!localStorage.getItem('manualModal')) {
  manualModal.open();
  localStorage.setItem('manualModal', 'true');
}
</script>

<template>
  <div class="px-5 py-14 pb-[50vh]">
    <div class="flex w-full items-center justify-between">
      <Icon name="gamers-heaven-logo" class="text-white shrink-0 h-12 w-40 z-[9999999]" />
      <Menu />
    </div>

    <ProgressNotification class="mt-8" />

    <div class="grid grid-cols-2 gap-x-5 mt-5">
      <GoodieCard />
      <LotteryCard />
    </div>

    <AchievementSoundtrack />

    <div class="text-white mt-10">
      <h2 class="text-28 font-bold">Deine Scans</h2>
      <div class="flex items-center gap-x-2.5 mt-4">
        <div class="shrink-0 size-5 -translate-y-0.5 border border-white rounded-full text-white flex items-center justify-center">
          <Icon name="info-i" class="shrink-0 size-2.5" />
        </div>
        <span class="text-16">Weitere Details zu unseren Aussteller:innen findest du weiter unten.</span>
      </div>
    </div>

    <div class="grid mt-7 grid-cols-4 gap-5">
      <Coin
        v-for="coin in coinStore.coins"
        :active="!coin.inactive"
        :key="coin.id"
      />
    </div>

    <div class="mt-10 space-y-6">
      <CoinOwner
        v-for="owner in coinStore.owners"
        :key="owner.id"
        :owner="owner"
      />
    </div>

    <div class="mt-24 pt-4 border-t border-white">
      <button @click="manualModal.open()" class="w-full button-black !flex !justify-center !items-center gap-x-5">
        <div class="bg-white rounded-full size-5 shrink-0 flex items-center justify-center">
          <Icon name="info-i" class="text-black size-3 shrink-0"/>
        </div>

        <span class="text-12">Spielanleitung anzeigen</span>
      </button>
    </div>

    <div class="fixed bottom-7 left-5 right-5 z-20">
      <router-link :to="{name: 'scan'}" class="button-white mx-auto">
        QR-Code Scannen
      </router-link>
    </div>
  </div>
</template>
