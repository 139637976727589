<script setup>
import { ref } from 'vue';
import FloatingLabelInput from '../components/Common/FloatingLabelInput.vue';
import GradientBackground from "../components/Common/GradientBackground.vue";
import Icon from "../components/Common/Icon.vue";
import Loader from '../components/Common/Loader.vue';
import { useAuthStore } from '../stores/auth';
import { useRouter } from 'vue-router';

const router = useRouter();

const authStore = useAuthStore();

const userData = ref(getUserData());

const isLoading = ref(false);
const error = ref(null);
const success = ref(false);

async function onSubmit(){
  isLoading.value = true;
  
  const response = await authStore.updateUser(userData.value);

  error.value = '';
  if(!response.success){
    error.value = response.error;
  }

  success.value = response.success;

  if(response.success){
    setTimeout(() => {
      router.push({name: 'home'})
    }, 2000)
  }

  isLoading.value = false;
}

function getUserData(){
  const userData = JSON.parse(JSON.stringify(authStore.user));
  userData.password = "";
  userData.birthDate = userData.birthDate.split('T')[0];
  return userData;
}

</script>

<template>
  <header class="px-5 pt-12 pb-5 text-white relative">
    <GradientBackground class="absolute left-0 top-0 size-full -z-10" />
    <Icon name="gamers-heaven-logo" class="h-14 w-40 mb-10"/>
    
    <router-link :to="{name: 'home'}" class="flex items-center gap-x-2 mb-5">
      <Icon name="caret-left" class="shrink-0 size-4"/>
      <span>Zurück</span>
    </router-link>

    <h2 class="text-40">Profil</h2>
  </header>
  <div class="bg-white pt-8 px-5 pb-[30vh]">
    <form @submit.prevent="onSubmit" class="mt-8">
      <div class="space-y-5">
          <FloatingLabelInput v-model="userData.gamerTag" label="Gamertag (Nickname)" name="gamerTag" :required="true" />
          <FloatingLabelInput v-model="userData.firstName" label="Vorname" name="firstName" :required="true" />
          <FloatingLabelInput v-model="userData.lastName" label="Nachname" name="lastName" :required="true" />
          <FloatingLabelInput v-model="userData.birthDate" label="Geburtsdatum" name="birthDate" type="date" :required="true" />
          <FloatingLabelInput v-model="userData.street" label="Straße / Hausnummer" name="street" :required="true" />
          
          <div class="grid gap-x-5 grid-cols-2">
              <FloatingLabelInput v-model="userData.zip" label="PLZ" name="zip" :required="true" />
              <FloatingLabelInput v-model="userData.city" label="Ort" name="city" :required="true" />
          </div>

          <FloatingLabelInput v-model="userData.country" label="Land" name="country" :required="true" />
          <FloatingLabelInput v-model="userData.email" label="Email" name="email" type="email" :disabled="true" />
          <FloatingLabelInput v-model="userData.password" label="Neues Passwort (optional)" name="password" type="password" />
      </div>

      <p v-if="error" v-text="error" class="text-violet mt-9"></p>
      <p v-if="success" class="text-green-500 mt-9">Änderungen erfolgreich, du wirst gleich weitergeleitet</p>

      <div class="grid gap-x-2 grid-cols-2 mt-8">
        <button type="submit" class="button-black w-full">
            <Loader class="mx-auto size-6" v-if="isLoading" />
            <span v-else>
              Speichern
            </span>
        </button>
        <router-link :to="{name: 'home'}" class="button-black w-full">
          <span>Abbrechen</span>
        </router-link>
      </div>
    </form>
  </div>
</template>
