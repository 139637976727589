<script setup>
import {computed, onMounted, ref} from 'vue';
import { useAuthStore } from '../../stores/auth';
import Icon from '../Common/Icon.vue';
import ManualModal from '../Manual/Modal.vue';
import { useModal } from 'vue-final-modal';
import useSound from '../../composables/useSound';

const authStore = useAuthStore();
const isOpen = ref(false);

const firstLetter = computed(() => {
    if(authStore.user?.firstName){
        return Array.from(authStore.user?.firstName)[0];
    }

    return null;
})

const { play: playAudio } = useSound('/bundles/gamingapp/sounds/click.mp3')

function openMenu(){
    isOpen.value = true;
}

function closeMenu(){
    isOpen.value = false;
}

function handleClickOutside() {
  setTimeout(() => {
    closeMenu();
  }, 100);
}

function toggleMenu(){
    isOpen.value = !isOpen.value;

    if(isOpen.value){
      playAudio();
    }
}

const manualModal = useModal({
    component: ManualModal,
    attrs: {
      onClose() {
        manualModal.close();
      }
    },
});
</script>

<template>
    <div class="relative">
        <button
            type="button"
            @click.stop="toggleMenu()"
            class="bg-white p-0.5 pr-3 rounded-full flex items-center gap-x-3"
        >
          <div class="bg-black size-8 text-white shrink-0 rounded-full flex items-center justify-center">
              <span class="block translate-y-0.5" v-text="firstLetter"></span>
          </div>
          <Icon name="caret-down" :class="isOpen ? 'rotate-180' : ''" class="size-4 shrink-0 transition-transform duration-300 ease-in-out" />
        </button>
        <div
            v-if="isOpen"
            v-click-outside="handleClickOutside"
            :class="isOpen ? 'translate-y-0 opacity-100 pointer-events-auto' : 'translate-y-5 opacity-0 pointer-events-none'"
            class="bg-white rounded-lg px-8 py-6 space-y-5 absolute right-0 top-full mt-2 z-40 transition duration-300 ease-in-out"
        >
            <router-link :to="{name: 'profile'}" class="flex items-center gap-x-5">
                <Icon name="profile" class="rounded-full size-5 shrink-0"/>
                <span>Profil</span>
            </router-link>
            <button type="button" @click="manualModal.open()" class="flex items-center gap-x-5">
                <div class="bg-black rounded-full size-5 shrink-0 flex items-center justify-center">
                    <Icon name="info-i" class="text-white size-3 shrink-0"/>
                </div>
                <span>Spielanleitung</span>
            </button>
            <button type="button" @click="authStore.logout()" class="flex items-center gap-x-5">
                <div class="bg-black rounded-full size-5 shrink-0 flex items-center justify-center">
                    <Icon name="logout" class="text-white size-3 shrink-0"/>
                </div>
                <span>Logout</span>
            </button>
        </div>
    </div>
</template>
