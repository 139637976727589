<script setup>
  import GradientBackground from "../components/Common/GradientBackground.vue";
  import Register from "../components/Auth/Register/Modal.vue";
  import Login from "../components/Auth/Login/Modal.vue";
  import Icon from "../components/Common/Icon.vue";
  import ManualModal from "../components/Manual/Modal.vue";
  import { useModal } from "vue-final-modal";
  import { ref } from 'vue';

  const isLogoVisible = ref(true);

  const loginModal = useModal({
    component: Login,
    attrs: {
      onClose() {
        loginModal.close();
      },
      async onRegister() {
        await loginModal.close();
        registerModal.open();
      },
      onClosed() {
        isLogoVisible.value = true;
      },
      onBeforeOpen() {
        isLogoVisible.value = false;
      },
    },
  });

  const registerModal = useModal({
    component: Register,
    attrs: {
      async onVerified() {
        await registerModal.close();
        loginModal.open();
      },
      onClose() {
        registerModal.close();
      },
      onClosed() {
        isLogoVisible.value = true;
      },
      onBeforeOpen() {
        isLogoVisible.value = false;
      },
    },
  });

  const manualModal = useModal({
    component: ManualModal,
    attrs: {
      onClose() {
        manualModal.close();
      }
    },
});
</script>

<template>
  <div class="px-5 py-20 min-h-screen flex flex-col">
    <GradientBackground class="fixed left-0 top-0 size-full -z-10" />
    <Icon name="gamers-heaven-logo" class="h-14 text-white mb-10 transition-opacity duration-300 ease-in-out" :class="isLogoVisible ? '' : 'opacity-0'" />

    <div class="mt-auto">
      <h1 class="text-white font-bold text-40 text-center">
        Scannen, <br />
        Sammeln & <br />Gewinnen
      </h1>

      <button type="button" @click="manualModal.open()" class="flex items-center gap-x-2 mt-10 mx-auto">
        <div class="size-5 shrink-0 bg-white rounded-full flex items-center justify-center">
          <Icon name="info-i" class="shrink-0 size-3 text-black" />
        </div>
        <p class="text-white underline text-16">Spielanleitung</p>
      </button>

      <div class="mt-12">
        <button type="button" @click="registerModal.open()" class="button-black block w-full mb-5">Registrieren</button>
        <button type="button" @click="loginModal.open()" class="button-white block w-full mb-5">Login</button>
      </div>
    </div>
  </div>
</template>
