import { createApp } from "vue";
import App from "./app/App.vue";
import router from "./app/router";
import { createPinia } from 'pinia'
import { createVfm } from 'vue-final-modal'
import vClickOutside from "click-outside-vue3"
import VueMatomo from 'vue-matomo'
import { getMatomoConfiguration } from './app/matomo/configuration'
import { trackPageView } from './app/matomo/tracking'

const vfm = createVfm();
const pinia = createPinia();
const matomoConfiguration = getMatomoConfiguration(router);

const app = createApp(App);
app.use(router);
app.use(pinia);
app.use(vfm);
app.use(VueMatomo, matomoConfiguration)
app.use(vClickOutside);
app.mount("#app");

trackPageView();
