<script setup>
    import { ref } from 'vue';
    import { useAuthStore } from '../../../../stores/auth';
    import FloatingLabelInput from '../../../Common/FloatingLabelInput.vue';
    import Loader from '../../../Common/Loader.vue';

    const emit = defineEmits(["login"]);

    const email = ref('');
    const error = ref(false);
    const success = ref(false);
    const isLoading = ref(false);

    const authStore = useAuthStore();

    async function onReset(){
        isLoading.value = true;
        const response = await authStore.resetPassword(email.value);

        isLoading.value = false;

        error.value = !response.success;
        success.value = response.success;

        if(success.value){
            email.value = ''
        }
    }
</script>

<template>
    <div>
        <img src="/bundles/gamingapp/images/coin-blue.png" class="mx-auto mb-14 size-44 object-contain drop-shadow-coin" />

        <h2 class="text-center font-bold text-40 mb-12">Passwort zurücksetzen</h2>

        <form @submit.prevent="onReset" class="space-y-5">
            <FloatingLabelInput v-model="email" label="Email" name="email" type="email" :required="true" />
            
            <p v-if="error" class="text-violet">Fehler beim Zurücksetzen.</p>
            <p v-if="success" class="text-green-500">Checke dein Postfach.</p>

            <button type="submit" class="button-black w-full">
                <Loader class="mx-auto size-6" v-if="isLoading" />
                <span v-else>
                    Zurücksetzen
                </span>
            </button>
        </form>

        <div class="flex justify-between gap-x-5 text-12 mt-7">
            <button type="button" @click="emit('login')">Zurück</button>
        </div>
    </div>
</template>
