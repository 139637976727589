<template>
    <div class="p-10 overflow-y-auto absolute left-0 top-0 w-full h-full">
        <h2 class="text-center mb-14 font-bold text-28">QR-Codes suchen</h2>
        
        <svg class="mb-14 mx-auto w-full max-w-36" viewBox="0 0 167 185" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.09033 35.3774H26.7864V47.8674C26.7864 54.1325 21.7094 59.2128 15.4484 59.2128C9.18742 59.2128 4.1104 54.1325 4.1104 47.8674V35.3774H4.09033Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M26.7664 35.3774H49.4625V47.8674C49.4625 54.1325 44.3854 59.2128 38.1244 59.2128C31.8634 59.2128 26.7864 54.1325 26.7864 47.8674V35.3774H26.7664Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M49.4625 35.3774H72.1586V47.8674C72.1586 54.1325 67.0816 59.2128 60.8206 59.2128C54.5596 59.2128 49.4826 54.1325 49.4826 47.8674V35.3774H49.4625Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M72.1586 35.3774H94.8547V47.8674C94.8547 54.1325 89.7777 59.2128 83.5167 59.2128C77.2557 59.2128 72.1786 54.1325 72.1786 47.8674V35.3774H72.1586Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M94.8346 35.3774H117.531V47.8674C117.531 54.1325 112.454 59.2128 106.193 59.2128C99.9317 59.2128 94.8547 54.1325 94.8547 47.8674V35.3774H94.8346Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M117.531 35.3774H140.227V47.8674C140.227 54.1325 135.15 59.2128 128.889 59.2128C122.628 59.2128 117.551 54.1325 117.551 47.8674V35.3774H117.531Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M140.227 35.3774H162.923V47.8674C162.923 54.1325 157.846 59.2128 151.585 59.2128C145.324 59.2128 140.247 54.1325 140.247 47.8674V35.3774H140.227Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M11.154 1H155.839C159.732 1 162.903 4.17269 162.903 8.06827V35.3775H4.09033V8.06827C4.09033 4.17269 7.26096 1 11.154 1Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M159.813 117.064H7.18073C3.7672 117.064 1 119.829 1 123.239C1 126.649 3.7672 129.414 7.18073 129.414H159.813C163.226 129.414 165.993 126.649 165.993 123.239C165.993 119.829 163.226 117.064 159.813 117.064Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M9.26758 57.0242V117.064" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M157.725 57.0242V117.064" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M157.725 129.414H9.26758V162.225H157.725V129.414Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M157.725 162.225H9.26758V183.992H157.725V162.225Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M132.2 80.6787H34.7732V117.064H132.2V80.6787Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M19.4216 79.9155V96.3814" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
        </svg>

        <p class="text-center">Bei jedem Stand befindet sich mindestens ein QR-Code.</p>
    </div>
</template>