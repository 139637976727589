<script setup>
import { VueFinalModal } from "vue-final-modal";
import Icon from '../Common/Icon.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import {onMounted, ref} from 'vue';
import Slide1 from './Slides/Slide1.vue';
import Slide2 from './Slides/Slide2.vue';
import Slide3 from './Slides/Slide3.vue';
import Slide4 from './Slides/Slide4.vue';
import {useAuthStore} from "../../stores/auth";
import {trackEvent} from "../../matomo/tracking";

const emit = defineEmits(["close"]);

const authStore = useAuthStore();

onMounted(() => {
  trackEvent('manual-view', authStore?.user?.email);
});

const paginationEl = ref(null);
</script>

<template>
  <VueFinalModal
        class="size-full"
        overlay-class="!bg-black/90"
        content-class="size-full flex flex-col p-5 py-8"
        swipe-to-close="down"
        content-transition="vfm-slide-down"
    >
        <button @click="emit('close')" type="button" class="flex items-center justify-center size-12 shrink-0 ml-auto bg-white rounded-full text-black mb-5">
            <Icon name="x" class="shrink-0 size-3" />
            <span class="sr-only">Zurück</span>
        </button>

        <div class="grow relative">
            <div class="absolute left-0 top-0 w-full h-full flex flex-col">
                <div class="grow">
                    <Swiper
                        :slides-per-view="1"
                        :space-between="50"
                        :pagination="{ clickable: true, el: paginationEl }"
                        :modules="[Pagination]"
                        class="h-full !overflow-visible"
                    >
                        <SwiperSlide class="bg-white rounded-3xl relative">
                            <Slide1 />
                        </SwiperSlide>
                        
                        <SwiperSlide class="bg-white rounded-3xl relative">
                            <Slide2 />
                        </SwiperSlide>
                        
                        <SwiperSlide class="bg-white rounded-3xl relative">
                            <Slide3 />
                        </SwiperSlide>

                        <SwiperSlide class="bg-white rounded-3xl relative">
                            <Slide4 @close="emit('close')" />
                        </SwiperSlide>
                    </Swiper>
                </div>
        
                <div ref="paginationEl" class="pagination-manual shrink-0 mt-5"></div>
            </div>
        </div>
  </VueFinalModal>
</template>
