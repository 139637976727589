<script setup>
import { useAuthStore } from '../../stores/auth';
import { useCoinStore } from '../../stores/coin';
import {useModal} from "vue-final-modal";
import LotteryModal from "../Lottery/Modal";

const coinStore = useCoinStore();
const authStore = useAuthStore();

const lotteryModal = useModal({
  component: LotteryModal,
  attrs: {
    onClose() {
      lotteryModal.close();
    }
  },
});

</script>

<template>
    <div class="bg-gradient-green text-white rounded-md p-6"
         @click="lotteryModal.open()"
    >
        <h2 class="text-center font-bold mb-8">Verlosung</h2>

        <svg class="w-24 h-20 mx-auto mb-8" viewBox="0 0 87 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.6433 18.5908H0.511719V58.9483H74.6433V18.5908Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M55.262 23.5022H18.8672V28.3626H55.262V23.5022Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M26.2432 18.5808C26.2432 16.1913 28.1997 14.2593 30.5966 14.2593H44.5584C46.9656 14.2593 48.9118 16.2015 48.9118 18.5808H26.2432Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M34.1816 9.93774H40.973C42.5709 9.93774 43.8719 11.2291 43.8719 12.8153V14.2592H31.2725V12.8153C31.2725 11.2291 32.5734 9.93774 34.1713 9.93774H34.1816Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M79.0077 32.2773H74.6543V45.2519H79.0077V32.2773Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M86.2694 35.1548H79.0068V42.364H86.2694V35.1548Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M82.6436 35.1547V19.5974" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M84.7939 1H80.4814V19.5976H84.7939V1Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M21.5821 59.7517C21.5821 63.1275 18.8266 65.8628 15.4258 65.8628C12.025 65.8628 9.26953 63.1275 9.26953 59.7517" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M65.8731 59.7517C65.8731 63.1275 63.1177 65.8628 59.7168 65.8628C56.316 65.8628 53.5605 63.1275 53.5605 59.7517" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M17.2181 65.588L12.5573 81.8062H8.45996L13.1719 65.4253" stroke="white" stroke-miterlimit="10"/>
            <path d="M17.2181 65.588L12.5573 81.8062H8.45996L13.1719 65.4253" stroke="white" stroke-miterlimit="10"/>
            <path d="M61.9817 65.4253L66.6937 81.8062H62.5963L57.9355 65.588" stroke="white" stroke-miterlimit="10"/>
            <path d="M12.0666 54.1999C14.058 54.1999 15.6723 52.5974 15.6723 50.6207C15.6723 48.644 14.058 47.0415 12.0666 47.0415C10.0753 47.0415 8.46094 48.644 8.46094 50.6207C8.46094 52.5974 10.0753 54.1999 12.0666 54.1999Z" stroke="white" stroke-miterlimit="10"/>
            <path d="M20.8235 46.5432C22.8148 46.5432 24.4291 44.9407 24.4291 42.964C24.4291 40.9872 22.8148 39.3848 20.8235 39.3848C18.8321 39.3848 17.2178 40.9872 17.2178 42.964C17.2178 44.9407 18.8321 46.5432 20.8235 46.5432Z" stroke="white" stroke-miterlimit="10"/>
            <path d="M30.4738 54.1999C32.4652 54.1999 34.0795 52.5974 34.0795 50.6207C34.0795 48.644 32.4652 47.0415 30.4738 47.0415C28.4825 47.0415 26.8682 48.644 26.8682 50.6207C26.8682 52.5974 28.4825 54.1999 30.4738 54.1999Z" stroke="white" stroke-miterlimit="10"/>
            <path d="M35.5959 45.2516C37.5873 45.2516 39.2016 43.6492 39.2016 41.6725C39.2016 39.6957 37.5873 38.0933 35.5959 38.0933C33.6046 38.0933 31.9902 39.6957 31.9902 41.6725C31.9902 43.6492 33.6046 45.2516 35.5959 45.2516Z" stroke="white" stroke-miterlimit="10"/>
            <path d="M45.3166 54.1999C47.308 54.1999 48.9223 52.5974 48.9223 50.6207C48.9223 48.644 47.308 47.0415 45.3166 47.0415C43.3253 47.0415 41.7109 48.644 41.7109 50.6207C41.7109 52.5974 43.3253 54.1999 45.3166 54.1999Z" stroke="white" stroke-miterlimit="10"/>
            <path d="M37.5725 73.8344C39.5638 73.8344 41.1782 72.232 41.1782 70.2552C41.1782 68.2785 39.5638 66.676 37.5725 66.676C35.5811 66.676 33.9668 68.2785 33.9668 70.2552C33.9668 72.232 35.5811 73.8344 37.5725 73.8344Z" stroke="white" stroke-miterlimit="10"/>
            <path d="M62.3205 54.1999C64.3119 54.1999 65.9262 52.5974 65.9262 50.6207C65.9262 48.644 64.3119 47.0415 62.3205 47.0415C60.3292 47.0415 58.7148 48.644 58.7148 50.6207C58.7148 52.5974 60.3292 54.1999 62.3205 54.1999Z" stroke="white" stroke-miterlimit="10"/>
        </svg>

        <p class="text-center font-bold text-28 mb-3">
            <span v-text="coinStore.collectedCoinAmount"></span>
            <span> / </span>
            <span v-text="coinStore.totalCoinAmount"></span>
        </p>
        <p class="text-center font-bold text-16">Lose</p>
    </div>
</template>
