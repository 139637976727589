<script setup>
import { ref } from 'vue';
import CodeInput from '../../../Common/CodeInput.vue';
import Loader from '../../../Common/Loader.vue';

const emit = defineEmits(["verified"]);

const props = defineProps({
  email: {
    type: [String],
  }
});

const isLoading = ref(false);
const code = ref(null);

const error = ref(false);
const success = ref(false);

async function onSubmit(){
    isLoading.value = true;

    const response = await fetch('/api/gamersheaven/auth/verify', {
        method: 'POST',
        body: JSON.stringify({
            code: code.value,
            email: props.email
        })
    })

    const json = await response.json();

    isLoading.value = false;

    if(json.success){
        success.value = true;

        setTimeout(() => {
            emit('verified');
        }, 2000)

        return;
    }
    
    error.value = true;
}
</script>

<template>
    <div>
        <img src="/bundles/gamingapp/images/coin-green.png" class="mx-auto mb-14 size-44 object-contain drop-shadow-coin" />

        <div class="text-center">
            <h2 class="text-green-400 font-bold text-40 mb-10">Registrierung erfolgreich</h2>
            <p>Wir haben dir einen Code via E-Mail gesendet. Bitte hier unten eingeben.</p>
        </div>
        <form @submit.prevent="onSubmit" class="mt-12">
            <CodeInput v-model="code" :digits="5" />

            <p v-if="success" class="text-green-500 mt-9">Richtiger Code. Du wirst gleich weitergeleitet.</p>
            <p v-if="error" class="text-violet mt-9">Ungültiger Code</p>

            <button type="submit" class="button-black w-full mt-9">
                <Loader class="mx-auto size-6" v-if="isLoading" />
                <span v-else>
                    Bestätigen
                </span>
            </button>
        </form>
    </div>
</template>
