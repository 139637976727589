<script setup>
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '../../stores/auth';
import { useCoinStore } from '../../stores/coin';
import GoodieModal from "../Goodie/Modal.vue";
import { nextTick, ref } from "vue";
import ConfettiExplosion from "vue-confetti-explosion";
import LotteryModal from "../Lottery/Modal";
import useSound from "../../composables/useSound";

const coinStore = useCoinStore();
const authStore = useAuthStore();

const max = 5;

const { play: playAudio } = useSound('/bundles/gamingapp/sounds/bomb.mp3')

const goodieModal = useModal({
    component: GoodieModal,
    attrs: {
      onClaim() {
        goodieModal.close();
        authStore.claimGoodie();
        playAudio();
        animateConfetti();
      },
      onClose() {
        goodieModal.close();
      }
    },
});

const lotteryModal = useModal({
  component: LotteryModal,
  attrs: {
    onClose() {
      lotteryModal.close();
    }
  },
});

const isConfettiVisible = ref(false);

const animateConfetti = async () => {
    isConfettiVisible.value = false;
    await nextTick();
    isConfettiVisible.value = true;
};
</script>

<template>
    <div class="bg-gradient-pink relative text-white rounded-md p-6 flex flex-col"
         @click="!authStore.user.goodieClaimed && coinStore.collectedCoinAmount < max ? lotteryModal.open() : null"
    >
        <ConfettiExplosion class="absolute left-1/2 top-1/2" v-if="isConfettiVisible" />

        <h2 class="text-center font-bold mb-8">Goodie</h2>

        <svg class="w-24 h-20 mb-8 mx-auto" viewBox="0 0 95 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M62.949 17.2414C62.31 17.2074 61.6711 17.1848 61.0321 17.1848C60.3932 17.1848 59.7998 17.1848 59.1837 17.2414C56.799 17.3659 54.4713 17.7395 52.2464 18.3508C45.7427 20.1055 40.0262 23.7621 35.7589 28.6754C30.6928 34.4829 27.6463 42.0452 27.6463 50.3093C27.6463 52.5169 27.8631 54.6791 28.2853 56.7735C28.7417 59.0829 29.4491 61.3131 30.3733 63.4301C30.864 64.5735 31.4231 65.6829 32.0506 66.7584C14.502 65.7735 0.570312 51.3508 0.570312 33.6904C0.570312 16.0301 15.5289 0.565918 33.9675 0.565918C46.3702 0.565918 57.1983 7.27913 62.949 17.2414Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M59.1835 17.2415C56.7988 17.366 54.4712 17.7396 52.2462 18.3509C45.7425 20.1056 40.0261 23.7623 35.7587 28.6755C30.6927 34.483 27.6462 42.0453 27.6462 50.3094C27.6462 52.517 27.863 54.6792 28.2851 56.7736C28.7415 59.083 29.449 61.3132 30.3732 63.4302C15.3918 61.6755 3.77637 49.0189 3.77637 33.6906C3.77637 17.1509 17.2973 3.73584 33.9673 3.73584C44.5102 3.73584 53.798 9.10188 59.1835 17.2415Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M94.4294 50.3093C94.4294 68.6037 79.4709 83.4339 61.0323 83.4339C48.6295 83.4339 37.8014 76.7207 32.0508 66.7584C31.4232 65.6829 30.8641 64.5735 30.3735 63.4301C29.4493 61.3131 28.7418 59.0829 28.2854 56.7735C27.8633 54.6792 27.6465 52.5169 27.6465 50.3093C27.6465 42.0452 30.693 34.4829 35.759 28.6754C40.0264 23.7622 45.7428 20.1056 52.2465 18.3509C54.4715 17.7395 56.7991 17.3659 59.1838 17.2414C59.8 17.1961 60.4161 17.1848 61.0323 17.1848C61.6484 17.1848 62.3102 17.2075 62.9491 17.2414C80.4978 18.2263 94.4294 32.649 94.4294 50.3093Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M52.2461 18.3509C45.7424 20.1056 40.026 23.7622 35.7586 28.6754C30.6926 34.4829 27.6461 42.0452 27.6461 50.3094C27.6461 52.5169 27.8629 54.6792 28.2851 56.7735C17.7992 54.249 10.0176 44.8641 10.0176 33.6905C10.0176 20.5697 20.7316 9.92822 33.9673 9.92822C41.2925 9.92822 47.8647 13.1999 52.2461 18.3509Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M35.7594 28.6755H26.6314H22.9916H18.5303" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M19.6025 27.9397C19.9905 26.8982 20.4241 26.0378 21.1657 26.0378C21.9074 26.0378 22.3409 26.8982 22.7289 27.9397C23.288 29.4454 23.7216 31.3133 24.8169 31.3133C25.9123 31.3133 26.3459 29.4454 26.905 27.9397C27.2929 26.8982 27.7265 26.0378 28.4681 26.0378C29.2098 26.0378 29.6434 26.8982 30.0313 27.9397" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>

        <div class="mt-auto">
            <div v-if="authStore.user.goodieClaimed">
                <p class="text-center font-bold text-16">
                    Abgeholt
                </p>
            </div>
            <div v-else-if="coinStore.collectedCoinAmount >= max">
               <button @click="goodieModal.open()" type="button" class="bg-white rounded-full px-3 w-full text-center py-3 text-16 text-black">Abholen</button>
            </div>
            <div v-else>
                <p class="text-center font-bold text-28 mb-3">
                    <span v-text="Math.min(max, coinStore.collectedCoinAmount)"></span>
                    <span> / </span>
                    <span v-text="max"></span>
                </p>
                <p class="text-center font-bold text-16">Noch <span v-text="max - coinStore.collectedCoinAmount"></span> Scans</p>
            </div>
        </div>
    </div>

</template>
