<script setup>
import { onMounted, ref } from 'vue';
import Loader from '../Common/Loader.vue';
import { QrcodeStream } from 'vue-qrcode-reader'

const emit = defineEmits(["camera-error", "scan", "scan-error"]);
const isLoading = ref(true);

function onScan(codes){
    if(codes.length === 0){
      return;
    }
    
    const url = getQrCodeUrl(codes);

    // Unable to get url -> do nothing
    if(!url){
        return;
    }
    
    // Scanned url is not a gaming app url -> scan error
    if(!url.includes(window.location.hostname)){
        emit('scan-error');
        return;
    }
    
    const hash = extractHashFromUrl(url);
    
    // Scanned url is missing the hash
    if(hash === false){
        emit('scan-error');
        return;
    }

    if(hash){
        emit('scan', hash)
    }
}

function extractHashFromUrl(url){
    // Get query string
    let queryString = url.split('?')[1];
    if (!queryString) {
        return false; 
    }

    // Split query strings
    let queryParams = queryString.split('&');

    // Find query param
    for (let i = 0; i < queryParams.length; i++) {
        let pair = queryParams[i].split('=');
        if (decodeURIComponent(pair[0]) === 'hash') {
            return decodeURIComponent(pair[1]); 
        }
    }

    return false;
}

function getQrCodeUrl(codes){
    // Get url of first scanned qr code in codes array
    for (let i = 0; i < codes.length; i++) {
      const element = codes[i];
      if(element.format === 'qr_code'){
        return element.rawValue;
      }
    }

    return null;
  }
</script>

<template>
    <h1 class="shrink-0 font-bold mb-10 text-center text-28">QR-Code scannen</h1>
    <div class="relative grow rounded-md overflow-hidden">
        <div v-if="isLoading" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loader class="size-10" />
        </div>

        <QrcodeStream
            @detect="onScan"
            @error="emit('camera-error')"
            :formats="['qr_code']"
            @camera-on="isLoading = false"
            v-show="!isLoading"
        />
    </div>
</template>