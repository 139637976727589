import {ref, onMounted, onUnmounted} from 'vue'

export default function useSound(src) {
    const audioContext = ref(null)
    const audioBuffer = ref(null)

// Lade die Audio-Datei
    const loadAudio = async () => {
        const cache = await caches.open('audio-cache')
        const response = await cache.match(src)
        if (response) {
            const arrayBuffer = await response.arrayBuffer()
            audioBuffer.value = await audioContext.value.decodeAudioData(arrayBuffer)
        } else {
            const response = await fetch(src)
            const arrayBuffer = await response.arrayBuffer()
            cache.put(src, new Response(arrayBuffer, {headers: response.headers}))
            audioBuffer.value = await audioContext.value.decodeAudioData(arrayBuffer)
        }
    }

    const play = () => {
        if (audioBuffer.value) {
            const source = audioContext.value.createBufferSource()
            source.buffer = audioBuffer.value
            source.connect(audioContext.value.destination)
            source.start(0)
        }
    }

    onMounted(() => {
        if ('AudioContext' in window || 'webkitAudioContext' in window) {
            audioContext.value = new (window.AudioContext || window.webkitAudioContext)()
            loadAudio()
        }
    })

    onUnmounted(() => {
        if (audioContext.value && audioContext.value.close) {
            audioContext.value.close()
        }
    })

    return {
        play
    }
}