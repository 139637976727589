<script setup>
import { VueFinalModal } from "vue-final-modal";
import Icon from "../Common/Icon.vue";
import SwipeButton from "../Common/SwipeButton.vue";
import { ref } from "vue";

const emit = defineEmits(["close", "claim"]);

const STATE_INFO = "info";
const STATE_CLAIM = "claim";

const state = ref(STATE_INFO);
</script>

<template>
  <VueFinalModal
    class="size-full"
    overlay-class="!bg-black/90"
    content-class="size-full flex flex-col p-5 pt-14"
    swipe-to-close="down"
    content-transition="vfm-slide-down"
>
    <button
        @click="emit('close')"
        type="button"
        class="flex items-center justify-center size-12 shrink-0 ml-auto bg-white rounded-full text-black mb-10"
    >
      <Icon name="x" class="shrink-0 size-3" />
      <span class="sr-only">Zurück</span>
    </button>

    <div class="bg-white rounded-3xl grow overflow-x-hidden">
      <div
        :class="state === STATE_CLAIM ? '-translate-x-full' : ''"
        class="flex items-start justify-start transition-transform duration-300 ease-in-out"
      >
        <div class="shrink-0 w-full p-8 overflow-y-scroll">
          <h2 class="font-bold text-center text-28 mb-6">Exklusives Gamers Heaven Goodie unlocked</h2>
          <img class="w-auto max-h-40 mx-auto mb-2" src="/bundles/gamingapp/images/goodie.png" role="presentation" />
          <p class="text-center mb-7">Hol dir dein Sammlerstück doch gleich am Gamers Heaven Infostand im Foyer ab.</p>
          <button type="button" @click="state = STATE_CLAIM" class="button-black w-full">Alles klar!</button>
        </div>
        
        <div class="shrink-0 w-full p-8 overflow-y-scroll">
          <h2 class="font-bold text-center text-28 mb-9">Goodie Abholen</h2>
          <p class="font-bold text-center mb-6">Erst bei Abholung nach rechts swipen!</p>
          <img class="w-auto max-h-40 mx-auto mb-2" src="/bundles/gamingapp/images/goodie.png" role="presentation" />
          <SwipeButton text="Swipe" @swipe="emit('claim')" class="mb-3 w-full" />
          <button type="button" class="button-black w-full" @click="emit('close')">Schließen</button>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>
