<script setup>
  import { useRoute, useRouter } from 'vue-router';
  import Loader from '../components/Common/Loader.vue';
  import { useAuthStore } from '../stores/auth';
  import { onMounted } from 'vue';
import Icon from '../components/Common/Icon.vue';

  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore();

  onMounted(() => {
    const referer = route.query.referer;
    
    if(!referer){
      return;
    }

    // Wait until authenticated, then redirect
    const interval = setInterval(() => {
      if(!authStore.isLoading){
        router.push(decodeURI(route.query.referer))
        clearInterval(interval);
      }
    }, 100)
  })
</script>

<template>
  <div class="px-5 py-14 flex flex-col items-center h-full">
    <div class="w-2/3 my-auto relative aspect-square">
      <Loader class="text-blue-400 absolute left-0 top-0 size-full" />
    </div>
    
    <Icon name="gamers-heaven-logo" class="text-white shrink-0 h-12 w-40" />
  </div>
</template>
