<script setup>
import { VueFinalModal } from "vue-final-modal";
import Icon from '../Common/Icon.vue';
import { ref } from 'vue';
import Slide4 from '../Manual/Slides/Slide4.vue';

const emit = defineEmits(["close"]);

const paginationEl = ref(null);
</script>

<template>
  <VueFinalModal
        class="size-full"
        overlay-class="!bg-black/90"
        content-class="size-full flex flex-col p-5 py-8"
        swipe-to-close="down"
        content-transition="vfm-slide-down"
    >
        <button @click="emit('close')" type="button" class="flex items-center justify-center size-12 shrink-0 ml-auto bg-white rounded-full text-black mb-5">
            <Icon name="x" class="shrink-0 size-3" />
            <span class="sr-only">Zurück</span>
        </button>

        <div class="grow relative">
            <div class="absolute left-0 top-0 w-full h-full flex flex-col bg-white rounded-3xl relative">
                <div class="grow">
                  <Slide4 @close="emit('close')" />
                </div>
            </div>
        </div>
  </VueFinalModal>
</template>
