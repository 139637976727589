import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useAuthStore } from "./auth";
import useSound from '../composables/useSound';
import { trackEvent } from "../matomo/tracking";

export const useCoinStore = defineStore("coins", () => {
  const coins = ref([]);
  const owners = ref([]);
  const totalCoinAmount = ref(0);

  const authStore = useAuthStore();

  async function fetchCoins() {
    if (authStore.isLoggedIn) {
      const response = await authStore.fetchAuthenticated(`/api/gamersheaven/u/de/coins`);
      const json = await response.json();

      if(json.success){
        coins.value = json.data.coins;
        owners.value = json.data.owners;
        totalCoinAmount.value = json.data.totalCoins;
      }
    }
  }

  const collectedCoinAmount = computed(() => {
    let amount = 0;

    coins.value.forEach(coin => {
      if(!coin.inactive){
        amount++;
      }
    })

    return amount;
  })

  const progress = computed(() => {
    return collectedCoinAmount.value / totalCoinAmount.value;
  })

  const { play: playSuccessAudio } = useSound('/bundles/gamingapp/sounds/coin.mp3')
  const { play: playErrorAudio } = useSound('/bundles/gamingapp/sounds/error.mp3')

  async function submitCoinHash(hash) {
    const response = await authStore.fetchAuthenticated("/api/gamersheaven/u/de/scan", {
      method: "POST",
      body: JSON.stringify({
        hash: hash
      })
    });

    const json = await response.json();

    if(json.success){
      coins.value = json.data.coins;
      owners.value = json.data.owners;
      totalCoinAmount.value = json.data.totalCoins;
      playSuccessAudio();

      trackEvent("coin-scan", authStore.user?.email + ', ' + json.coin?.name + ', ' + json.coin?.genre);
    } else {
      playErrorAudio();
    }

    return json;
  }

  return {
    coins,
    owners,
    totalCoinAmount,
    fetchCoins,
    collectedCoinAmount,
    progress,
    submitCoinHash
  };
});
