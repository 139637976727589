<script setup>
import { VueFinalModal } from "vue-final-modal";
import Icon from "../../Common/Icon.vue";
import { ref } from "vue";
import Login from './State/Login.vue';
import Reset from './State/Reset.vue';

const emit = defineEmits(["close", "login", "register"]);
const isTopVisible = ref(false);

const STATE_LOGIN = 'state-login';
const STATE_RESET = 'state-reset';

const state = ref(STATE_LOGIN)
</script>

<template>
  <VueFinalModal
        class="size-full"
        content-class="size-full flex flex-col"
        swipe-to-close="down"
        content-transition="vfm-slide-down"
        :hide-overlay="true"
        @opened="isTopVisible = true"
        @beforeClose="isTopVisible = false"
    >
        <div
            :class="isTopVisible ? '' : 'opacity-0'"
            class="text-white px-5 py-10 shrink-0 transition-opacity duration-300 ease-in-out"
        >
            <button @click="emit('close')" type="button" class="flex items-center gap-x-2 mb-5">
                <Icon name="caret-left" class="shrink-0 size-4" />
                <span>Zurück</span>
            </button>
            <h2 class="text-40">Login</h2>
        </div>

        <div class="bg-white rounded-t-3xl grow pt-5 flex flex-col">
            <div class="pb-5 shrink-0">
                <div class="bg-black w-14 h-1.5 rounded-full mx-auto"></div>
            </div>

            <div class="relative grow">
                <div ref="container" class="absolute pt-2 pb-[20vh] left-0 top-0 size-full overflow-y-auto px-5">
                    <Login @register="emit('register')" @reset="state = STATE_RESET" v-if="state === STATE_LOGIN" />
                    <Reset @login="state = STATE_LOGIN" v-if="state === STATE_RESET" />
                </div>
            </div>
        </div>
  </VueFinalModal>
</template>
